import React, { Component } from 'react';
import axios from 'axios';

import Measure from '../../containers/Measure/Measure';

import styles from './MeasureList.module.css';

class MeasureList extends Component {
    state = {
        measures: [],
        selectedMeasureIDId: null
    }

    componentDidMount() {
        axios.get('data/sensorData.json')
            .then(response => {
                const measuresRaw = response.data.sort ((a,b)=> b.dateNumber-a.dateNumber) .slice(0, 100);
                const measures = measuresRaw.map(m => {
                    return {
                        id: m.dateNumber,
                        date: m.dateString,
                        piCam:  m.results[0],
                        webCam:  m.results[1],
                        inDoor: m.results[2],
                        humid: m.results[3],
                        outDoor: m.results[4]
                    }
                });
                this.setState({ measures: measures });
                console.log(measures)
            });
    }

    render() {
        const measures=this.state.measures.map( m =>
            { return <Measure 
                key = {m.id}
                picam = {m.piCam}
                webcam = {m.webCam}
                indoor = {m.inDoor}
                humid = {m.humid}
                outdoor = {m.outDoor}
                mdate ={m.date}
            />});
        return (
            <div >
                 {measures}
            </div>
        );
    }
}

export default MeasureList;