import React, { Component } from 'react';

import styles from './Measure.module.css';

class Measure extends Component {

    render() {

        let img1 ='data/cameraImage_1577800705917.jpg';
        return (
            <div className={styles.container}>
                <div className={styles.dateDiv}>
                    {this.props.mdate}
                </div>
                <div className={styles.tempDiv}>
                    <div>Indoor temperature : {this.props.indoor}<span>&#8451;</span></div> 
                    <div>Humidity : {this.props.humid}</div>
                    <div>Outdoor temperature: {this.props.outdoor}<span>&#8451;</span></div> 
                </div>
                <div className={styles.imageDiv}><img className={styles.img} src={'data/' + this.props.picam} alt='bilde fra pi camera'></img></div>
                <div className={styles.imageDiv}><img className={styles.img} src={'data/' + this.props.webcam} alt='bilde fra web camera'></img></div>
                
            </div>
        );
    }
}

export default Measure;