import React from 'react';

import Layout from './components/Layout/Layout'
import MeasureList from './components/MeasureList/MeasureList'
import './App.css';

function App() {
  return (
    <div className="App">
      <Layout>
        <MeasureList></MeasureList>
      </Layout>
    </div>
  );
}

export default App;
