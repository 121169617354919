import React from 'react';
import Aux from '../../hoc/Aux'
import styles from  './Layout.module.css'

const layout = (props) => (
    <div className={styles.container}>
        <div className={styles.headerDiv}>
            Toolbar,Latest Measure, Measure List
            <img  className={styles.logo} src='Images/Nr15.png' alt='logo' />
        </div>
        <main >
              {props.children} 
        </main>
        <div>Footer</div>
    </div>
);

export default layout;